import { Col, Dropdown, Form, InputGroup } from "react-bootstrap";
import InputGroupText from "react-bootstrap/esm/InputGroupText";

import './FormInput.scss';
import '../../component/no-auth/signin/SignIn.scss';
import { useEffect, useState } from "react";

export const TextInput = (props) => {

    return (
        <Col className="form-control-custom">
            {props?.label && <Form.Label sm="sm" htmlFor={props.name}>{props.label}</Form.Label>}
            <Form.Control {...props}  size="sm" type={props.type ? props.type : 'text'} name={props.name} placeholder={props.placeholder} value={props.value}
                onChange={(event) => { props.onChange && props.onChange(event); }} autoComplete="off" />
           {props?.error && <div className="text-danger" style={{ minHeight: '18px', fontSize: '12px', marginTop: '4px' }}>{props.error ? props.error : ''}</div>}
        </Col>
    );
};
export const SignInTextInput = (props) => {

    return (
        <Col className="form-control-custom">
            <Form.Label sm="sm" htmlFor={props.name}>{props.label}</Form.Label>
            <InputGroup className="">
                <InputGroupText className="signicon" >
                   {props.name == "email" && <i class="fa fa-envelope" ></i>}
                   {props.name == "password" && <i class="fa fa-lock"></i>}
                    </InputGroupText>
                <span class="icon-envelope-o"></span>
                <Form.Control className={`signinput ${props.name == "password" && "signinputright"}`} size="md" type={props.type ? props.type : 'text'} name={props.name} placeholder={props.placeholder} value={props.value}
                    onChange={(event) => { props.onChange && props.onChange(event); }} autoComplete="off" />
                   {props.name == "password" &&  <InputGroupText className="signiconright"
                   role={"button"}  onClick={()=>{
                        props?.setType(props.type == 'password'?"text":'password')
                   }} >
                  {props.type == 'password'?<i class="fa fa-eye-slash"></i>:<i class="fa fa-eye"></i>}
                    </InputGroupText>}
            </InputGroup>
            <div className="text-danger" style={{ minHeight: '18px', fontSize: '12px', marginTop: '4px' }}>{props.error ? props.error : ''}</div>
        </Col>
    );
};

export const SelectInput = (props) => {
    return (
        <Col className="form-control-custom">
            <Form.Label sm="sm" htmlFor={props.name}>{props.label}</Form.Label>
            <Form.Select value={props.value} defaultValue={props.value} onChange={(event) => { props.onChange && props.onChange(event); }}>
                <option value={""} disabled>Please select a option</option>
                {
                    Array.isArray(props.options) && props.options.length > 0 ?
                        props.options.map((elem) => <option value={elem.value}>{elem.option}</option>) : ''
                }
            </Form.Select>
            <div className="text-danger" style={{ minHeight: '18px', fontSize: '12px', marginTop: '4px' }}>{props.error ? props.error : ''}</div>
        </Col>
    );
};



export const DropdownInput = (props) => {
    
    const [displayText, setDisplayText] = useState(props.value);

    useEffect(() => {
        if (props.value && Array.isArray(props.options) && props.options.length > 0) {
            setDisplayText(props.options.find(elem => { return elem.id === props.value; }).value);
        }
    }, [props.value]);

    return (
        <Col className="form-control-custom">
            {props.label ? <Form.Label sm="sm" htmlFor={props.name}>{props.label}</Form.Label> : '' }
            <Dropdown onSelect={(eventKey, event) => { props.onChange && props.onChange(event.target.innerText); }}>
                <Dropdown.Toggle  size="sm" variant="default" style={displayText ? {color: '#000000'} : {}}>
                    {displayText ? displayText : (props.defaultValue || "Select a value" )}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        Array.isArray(props.options) && props.options.length > 0 ? props.options.map((elem, index) => <Dropdown.Item key={index}>{elem.value}</Dropdown.Item>) : ''
                    }
                </Dropdown.Menu>
            </Dropdown>
        </Col>
    );
};