import React, { Component } from "react";

import { Link } from 'react-router-dom';
import './Sidebar.scss';
import store from '../../redux/store';
import { actions } from "../../redux/actions";
export default class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.menus = [
            { name: 'Dashboard', redirectTo: '/home' ,imgname:"dashboard"},
            { name: 'Assessments', redirectTo: '/assessments',imgname:"folder"},
            { name: 'Users', redirectTo: '/users',imgname:"employee"},
            {name: 'Locations',redirectTo: '/locations',imgname:"loc"},
            {name: 'Messages',redirectTo: '/messages',imgname:"msg"},
            {name: 'Settings',redirectTo: '/settings',imgname:"setting"},
        ];

        // Checking where to open the sub menu or not for all menus
        this.menus.forEach(element => {
            if (Array.isArray(element.submenu) && element.submenu.length > 0) {
                if (this.props.location.pathname.includes(element.redirectTo)) {
                    element.openSubMenu = true;
                } else {
                    element.openSubMenu = element.submenu.filter(elem => { return this.props.location.pathname.includes(elem.redirectTo); }).length > 0 ? true : false
                }
            }
        });

        this.state = {
            menus: this.menus
        }
    }

    handleOpenSideMenu = (menuIndex) => {
        const menus = [...this.state.menus];
        menus.forEach((element, index) => {
            if (Array.isArray(element.submenu) && element.submenu.length > 0) {
                element.openSubMenu = menuIndex == index ? true : false;
            }
        });

        this.setState({ menus });
    };

    render() {
        return (
            <div className="d-flex flex-column px-2 sidebar-box border-right">
                <ul className="side-menu-container">
                    {
                        this.state.menus.map((menu, index) =>
                            <li key={`menu_${index}`} className={`menu ${this.props.location.pathname.includes(menu.redirectTo) ? 'active-menu ' : ''}`} onClick={() => this.handleOpenSideMenu(index)}>
                                <Link to={menu.redirectTo} onClick={() => {
                                    store.dispatch(actions.setScreenName(menu.name));
                                }}>
                                    <div className="d-flex  align-items-center">
                                    <img className="w-10 h-10 mx-3" src={`${process.env.PUBLIC_URL}/static/images/${menu.imgname}${ this.props.location.pathname.includes(menu.redirectTo)  ? "w":"g"}.png`} alt={menu.name} />
                                    <div className={`menu-title`} style={{color: this.props.location.pathname.includes(menu.redirectTo) ?"#FFF":"#585f69"}}>{menu.name}</div>
                                    </div>
                                    {Array.isArray(menu.submenu) && menu.submenu.length > 0 ? <i className={`fa fa-chevron-${menu.openSubMenu ? 'down' : 'right'}`}></i> : ''}
                                </Link>
                                {
                                    menu.openSubMenu && menu.submenu?.map((subMenu, subMenuIndex) =>
                                        <div key={`submenu_${index}_${subMenuIndex}`} className={` sub-menu ${ this.props.location.pathname.includes(menu.redirectTo) ? 'active-menu' : ''}`}>
                                            <Link to={subMenu.redirectTo}>{subMenu.name}</Link>
                                        </div>
                                    )
                                }
                            </li>
                        )
                    }
                </ul>
                <img style={{ marginBottom:"10px"}}  className="w-80 align-self-center" src={`${process.env.PUBLIC_URL}/static/images/adspace.jpg`} alt="ad" />
            </div>
        )
    }
}
