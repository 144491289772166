import React, { Component, createRef, useState } from "react";
import './Home.scss';
import '../../../shared/scss/theme.scss';
import { postApi } from "../../../shared/services/apiHelper";
import { assessmentList } from "../../../shared/settings/apiConfig";
import moment from "moment";
import { Form } from "react-bootstrap";
import { Datatable } from "../../../shared/datatable/Datatable";

const overview = [
    {
        name: "Total Assessments",
        icon: "msg",
        count: 1092,
        subname: "Currently Active",
        subcount: 25,
        color: "#f6f1ff"
    },
    {
        name: "Assessee's",
        icon: "group",
        count: 300,
        subname: "Currently Active",
        subcount: 205,
        color: "#eef9ff"
    },
    {
        name: "Certified",
        icon: "user",
        count: 298,
        subname: "Certificate's Expired",
        subcount: 298,
        color: "#eef9ff"
    }
]

export const Home = (props) => {

    const customDate = (dataValue) => {
        if (dataValue) {
            let date = moment.utc(dataValue).local();
            return `${date.format('DD/MM/YYYY')}`;
        } else
            return '- - -';
    };

    const customStatus = (status) => {
        return status?.isdeleted ? "Active" : "Inactive"
    }

    const editAction = (id) => {
        props.history.push(`/assessments/edit/${id}`);
    }

    const [config, setConfig] = useState({
        name: 'assessment',
        url: assessmentList,
        columns: [
            { name: '', property: '', width: '50px', columnType: 'custom', customColumn: (event) => checkBox(event), customHeader: 'selectAll' },
            { name: 'ASSESSMENT NAME', property: 'assessmentname', sort: true, width: '200px' },
            { name: 'TOPIC', property: 'subtitle', sort: true, width: '200px' },
            { name: 'ASSESSOR', property: 'subtitle', sort: true, showTooltip: true, width: '180px' },
            { name: 'CREATED DATE', property: 'createddate', sort: true, width: '100px', customColumn: customDate },
            { name: 'STATUS', property: 'isdeleted', sort: true, width: '50px', customColumn: customStatus },
            {
                name: '', property: '', columnType: 'actions', width: '50px',
                actions: {
                    header: "",
                    columns: [
                        { type: 'edit', action: editAction }
                    ]
                }
            },
        ],
        field: ['id', 'assessmentname', 'subtitle', 'description', 'createddate', 'isdeleted'],
        filter: [{ field: 'isdeleted', value: 0, op: '=', 'join': 'AND' }],
        sort: [{ "field": "assessmentname", "direction": "asc" }],
        pagination: true,
    });

    const dataTable = createRef();

    /* custom checbox */
    const checkBox = (value) => {
        return (
            <div>
                <Form>
                    <Form.Check className="d-flex align-items-center justify-content-center" type="checkbox" label="" value={value?.id} />
                </Form>
            </div>
        );
    }
    return (
        <div className="home-container">
            <div className="d-flex w-100 align-items-center ">
                <div className='   w-10 d-flex align-items-center  py-3'>
                    <img className={"mx-2 w-5 h-5"} src={`${process.env.PUBLIC_URL}/static/images/dashboard.png`} alt={"editing"} />
                    <h5 className='header my-1'>Overview</h5>
                </div>
                <div className='d-flex w-90 justify-content-end align-items-center'>
                    <div className="border-btn" role={"button"}>Create Assessment</div>
                    <div className="border-btn" role={"button"}>Add User</div>
                </div>
            </div>
            <div className="d-flex">
                {overview.map((item, index) => (
                    <div className="w-100 py-3  mx-1 position-relative" style={{ backgroundColor: item?.color }}>
                        <h5 style={{ fontWeight: "bolder" }} className="py-1 px-2">{item?.name}</h5>
                        <h4 style={{ fontWeight: "bolder" }} className=" px-2">{item?.count}</h4>
                        <div className="d-flex">
                            <text style={{ fontWeight: "bolder" }} className="py-1 px-2">{`${item?.subname} : `} </text>
                            <text style={{ fontWeight: "bolder" }} className="primarycolor py-1 px-2">{`${item?.subcount}`} </text>
                        </div>
                        <div className="w-10 d-flex position-absolute top-0 end-0 m-2 border rounded  border-2 align-items-center justify-content-center border-skyblue " >
                            <img className={"m-2 w-50 h-50"} src={`${process.env.PUBLIC_URL}/static/images/${item?.icon}.png`} alt={item?.name} />
                        </div>
                    </div>
                ))}
            </div>
            <div className="d-flex   w-100 align-items-center  py-3">
                <div className=' w-20 d-flex align-items-center '>
                    <img className={"mx-2 w-5 h-5"} src={`${process.env.PUBLIC_URL}/static/images/document.png`} alt={"document"} />
                    <h5 className='header my-1'>Detailed report</h5>
                </div>
                <div className='d-flex w-80 justify-content-end align-items-center'>
                    <button style={{ backgroundColor: "white" }} className=" d-flex align-items-center border border-1 border-primary py-1 rounded mx-1">
                        <img style={{ maxWidth: "30px" }} className={"mx-2 w-5 h-5"} src={`${process.env.PUBLIC_URL}/static/images/import.png`} alt={"import"} />
                        <text style={{ fontSize: "12px" }} className="primarycolor">Import</text>
                    </button>
                    <button style={{ backgroundColor: "white" }} className="d-flex align-items-center border border-1 border-primary py-1 rounded mx-1">
                        <img style={{ maxWidth: "30px" }} className={"mx-2 w-5 h-5"} src={`${process.env.PUBLIC_URL}/static/images/export.png`} alt={"export"} />
                        <text className="primarycolor" style={{ fontSize: "12px" }}>Export</text>
                    </button>
                </div>
            </div>
            <div style={{ padding: '15px' }}>
                <Datatable ref={dataTable} config={config} />
            </div>
        </div>
    );
}